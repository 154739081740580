import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { Auth0Provider } from '@auth0/auth0-react';




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
     <Auth0Provider
    domain="dev-f3b6qaq16ugz2iat.us.auth0.com"
    clientId="6X4FxEx2T6ECTAwYBN7Wf6Y6Bq5QisFz"
    
    
    
    authorizationParams={{
      redirect_uri: `${window.location.origin}`,
      audience:"https://token/user_info",
      scope:"openid profile email"
      
    }}
   
  
  >
    <App />
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
