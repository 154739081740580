import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import MainHeader from '../components/header';
import Footer from '../components/footer';

function MainLayout() {
    return (
        <Layout className="layout-default main-layout">
            <MainHeader />
            <Outlet />
            <Footer />
        </Layout>
    );
}

export default MainLayout;