export const AppRoutesConst = Object.freeze({
    // public
    login: 'login',
    register: 'register',
    caseReport: 'case-report',
    dischargeSummary:'discharge-summary',
    searchTreatment: 'search-treatment',
    drugDosage: 'drug-dosage-research',
    resources: 'resources',
    membership: 'membership',
    contactUs: 'contact-us',
    contributors: 'contributors',
    medboxbot:'chatmed',
    privacyPolicy: 'privacy-policy',
    termsCondition: 'terms-and-conditions'
} as const);



