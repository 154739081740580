import { Col, Row } from 'antd';
import './index.css';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGooglePlusG, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { AppRoutesConst } from '../../app-const';

export default function Footer() {
  const navigate=useNavigate();
  const year = new Date().getFullYear()
  const handleButtonClick = (route: string) => {
    if (route === "report") {
      navigate("/case-report");
      localStorage.setItem("selectedKey", "case report");
    } 
    else if(route ==="summary") {
      navigate("/discharge-summary");
      localStorage.setItem("selectedKey", "discharge summary");
    }
    else if (route ==="treatment"){
      navigate("/search-treatment");
      localStorage.setItem("selectedKey", "search treatment");

    }
    else{
      navigate("/drug-dosage-research");
      localStorage.setItem("selectedKey", "drug dosage research");
    }
  };
  return (
    <div className='main-footer'>
      <div className='container'>
        <Row className='footer'>
          <Col md={24} lg={24} sm={24}>
            <div className='max-sm:flex-col max-md:flex-col flex items-center justify-between gap-6 px-12'>
            <div className='text-white flex flex-col  cursor-pointer' >
              <h6 className='underline underline-offset-2' onClick={() => handleButtonClick("drug-dosage")}>Drug Dosage Search</h6>
              <h6 className='underline underline-offset-2'onClick={() => handleButtonClick("summary")}>Discharge Summary</h6>

            </div>

            <div className='text-center items-center justify-center md:w-full lg:w-1/3'>
              <h5 className='text-center mb-4 text-white'>Follow Us</h5>
              <ul className='social-links'>
                <li><Link to='/#'><FontAwesomeIcon icon={faFacebookF} color='#fff' /></Link></li>
                <li><Link to='/#'><FontAwesomeIcon icon={faGooglePlusG} color='#fff' /></Link></li>
                <li><Link to='/#'><FontAwesomeIcon icon={faInstagram} color='#fff' /></Link></li>
                <li><Link to='/#'><FontAwesomeIcon icon={faPinterest} color='#fff' /></Link></li>
              </ul>
            </div>
            <div className='text-white flex flex-col  cursor-pointer' >
              <h6 className='underline underline-offset-2' onClick={() => handleButtonClick("report")}>Clinical Case Report Generator</h6>
              <h6 className='underline underline-offset-2' onClick={() => handleButtonClick("treatment")}>Treatment-Diagnosis-Evaluation</h6>

            </div>
              
            </div>
           
          </Col>
        </Row>
      </div>
      <section className='bottom-bar'>
        <div className='container'>
          <p className='text-center mr-2'> RelayAccess LLC. All rights reserved. {year} <Link to={AppRoutesConst.contactUs}>Help & Feedback</Link> , <Link to={AppRoutesConst.termsCondition}>Terms of Use</Link> , <Link to={AppRoutesConst.privacyPolicy}>Privacy Policy</Link></p>
        </div>
      </section>
    </div>
  )
}
